.VerifyOtp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // margin: 40px 0px;
    .forgotpasswordtext{
        cursor: pointer;
    }
.buttontheme{
    width: 100%;
}
.Main {
    width: 70%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Light box shadow
    text-align: center;
  

        .Left {
            width: 50%;
            background-color: #EE2325;
            // height: 100px;
        }

        .Right {
            width: 50%;
            align-self: center;

            .form_inner {
                width: 70%;
                margin: auto;

                h1 {
                    text-align: center;
                }
            }

            .buttom_text {
                margin: 20px;
                text-align: center;
                font-size: 18px;
            }
        }

    }

    @media (max-width: 767px) {
        .Left {
            display: none;
        }

        .Right {
            width: 100% !important;
            align-self: auto !important;

            .form_inner {
                width: 95% !important;
            }
        }
    }
}