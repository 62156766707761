// .BlogDetails {
//     .BAnnerImage {

//         .banner-inner {
//             width: 100% !important;
//         }
//     }

//     h3 {
//         color: #8c0d0d;
//         font-weight: 600;
//         font-size: 18px;
//         text-transform: uppercase;
//         padding: 15px 0;
//         margin: 0;
//     }


//     p,
//     small {
//         font-family: 'Open Sans', sans-serif;
//         font-size: 14px;
//         line-height: 28px;
//     }

//     .Image {
//         // width: 604px !important;
//         // height: 270px !important;
//         border: 3px solid #cfcfcf;
//         border-radius: 4px;
//         padding: 1px;
//         width: 100%;
//         // height: auto;
//         min-height: 350px !important;
//     }

//     .blogdetails_container,
//     .blogdetails_container_main {
//         margin: 50px auto;
//         .listtt{
//             margin: 15px 0px;
//         }
//         li{
//             color: #1c1c1c;
//             font-family: 'Open Sans', sans-serif;
//             text-transform: uppercase;
//             font-size: 14px;
//             list-style: none;
//             line-height: 30px;
//         }
//     }

//     .details_desc {
//         margin: 20px 0px;

//         h4 {
//             font-weight: 700;
//         }
//     }

//     .blogdetails_container_main {
//         .full_heading {
//             h3 {
//                 font-size: 24px;
//                 background-color: #8c0d0d;
//                 color: #fff;
//                 padding: 8px;
//                 font-weight: 500;
//                 text-transform: capitalize;
//             }
//         }

//     }
// }
.BlogDetails {
    padding: 50px 0;

    .blogdetails_container {
        h3 {
            color: #8c0d0d;
            font-weight: 600;
            font-size: 18px;
            text-transform: uppercase;
            padding: 15px 0;
            margin: 0;
        }

        p {
            line-height: 28px;
            font-size: 14px;
        }

        .Image {

            border: 3px solid #cfcfcf;
            border-radius: 4px;
            padding: 1px;
            width: 100%;
            // height: auto;
            min-height: 350px !important;
        }
    }

    .blogdetails_container_main {
        h3 {
            background-color: #8c0d0d;
            color: #fff;
            padding: 8px;
            font-weight: 600;
            text-transform: capitalize;
            font-family: 'Oswald' !important;
        }

        li {
            list-style: none;
            display: block;
            line-height: 30px;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
        }
    }
}