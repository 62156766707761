.Signup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .Main {
    width: 70%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Light box shadow
    text-align: center;
  }

  h3 {
    font-size: 30px;
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    // border-bottom: 5px solid #8c0d0d;
    padding-bottom: 12px !important;
    margin-bottom: 30px !important;
    font-family: "Oswald", sans-serif !important;
    text-align: center;
  }

  .buttontheme {
    width: 100%;
  }

  // Additional styling for form items, if needed
  @media (max-width: 767px) {
    display: block;
    margin: 20px 0px;
    .Main {
      width: 100%;
    }
  }
}
