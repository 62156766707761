.carousel-wrapper {
    position: relative;

    .carousel-arrows {
        position: absolute;
        top: 21%;
        transform: translateY(-50%);
        width: 89%;
        justify-content: space-between;
        /* justify-content: space-around; */
        display: flex;
        margin: 0px 82px;
    }

    .prev-button,
    .next-button {
        // background-color: transparent;
        border: none;
        // cursor: pointer;
        width: 60px;
        height: 60px;
        background-color: #f29f05;
        border-radius: 50%;
        text-shadow: none;
        line-height: 60px !important;
        margin-top: 250px;
        color: white;
    }

    .sliderimages1 {
        background-image: url("../../assets/banner1.jpg");
        background-size: cover;
    }

    .sliderimages2 {
        background-image: url("../../assets/banner2.jpg");
        background-size: cover;

    }

    .sliderimages3 {
        background-image: url("../../assets/banner3.jpg");
        background-size: cover;

    }

    .text-center {
        padding: 20px;
        padding: 170px 335px;

        h4 {
            font-family: 'Oswald' !important;
            color: white;
            font-size: 44px;
            font-weight: 700;
            text-shadow: none;
            line-height: 60px;
            text-transform: uppercase;
            background-repeat: no-repeat;
            background-position: center bottom;

        }

        dd,
        span {
            color: #f29f05;
        }

        h6 {
            color: white;
            font-family: 'Oswald', sans-serif;
            text-shadow: none;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: .5px;
            padding-bottom: 10px;
        }

        p {
            font-size: 14px;
            text-shadow: none;
            padding: 0 30px;
            color: white;
            line-height: 28px;
        }

        .headingimage {
            text-align: -webkit-center;
        }

        a {
            background-color: #8c0d0d;
            padding: 8px 30px;
            border: 1px solid #fff;
            border-radius: 4px;
            color: #fff;
            text-transform: capitalize;
            display: inline-block;
            margin-top: 25px;
            text-shadow: none;
        }
    }

    @media (max-width:767px) {
        img {
            width: 80%;
        }

        .text-center {
            padding: 20px 0px !important;
        }

        .prev-button,
        .next-button {
            margin-top: 20px;
        }

        .hidden-xs {
            display: none;
        }

        h4 {
            font-size: 18px !important;
            line-height: 26px !important;
        }

        .carousel-arrows {
            width: 100%;
            margin: 40px 0px;
            padding: 20px;
        }

        .headingimage {
            padding: 0px 65px !important;
        }
        .carousel-wrapper .next-button {

            border: none;
            width: 40px !important;
            height: 40px !important;
            background-color: #f29f05;
            border-radius: 50%;
            text-shadow: none;
            /* line-height: 60px !important; */
            margin-top: 250px;
            color: white;
        }
    }

    /* Extra small devices (phones) */
    @media (max-width: 575.98px) {
        img {
            width: 80%;
        }

        .text-center {
            padding: 80px 0px;
        }

        .carousel-arrows {
            width: 100%;
            margin: 0;
            margin: 40px 0px;

        }

        .headingimage {
            padding: 0px 65px !important;
        }

        .carousel-wrapper .next-button {

            border: none;
            width: 40px !important;
            height: 40px !important;
            background-color: #f29f05;
            border-radius: 50%;
            text-shadow: none;
            /* line-height: 60px !important; */
            margin-top: 250px;
            color: white;
        }
    }

    /* Small devices (tablets) */
    @media (min-width: 576px) and (max-width: 767.98px) {
        .text-center {
            padding: 80px 0px;
        }

        .carousel-arrows {
            width: 100%;
            margin: 0;
            padding: 20px;
        }

       
    }

    /* Medium devices (desktops) */
    @media (min-width: 768px) and (max-width: 991.98px) {
        .text-center {
            padding: 80px 0px;
        }

        .carousel-arrows {
            width: 100%;
            margin: 0;
            padding: 20px;
        }
    }

    /* Large devices (desktops) */
    @media (min-width: 992px) and (max-width: 1199.98px) {
        .text-center {
            padding: 80px 0px;
        }
    }

    /* Extra large devices (large desktops) */
    @media (min-width: 1200px) {}

}