.CardComponent {
    font-family: 'Oswald' !important;

    .card-body {
        // height: 80px !important;
        padding: 8px !important;
        place-content: center;

    }

    .custom_card {
        // background: linear-gradient(#f29f05, #fff) !important;
        background: linear-gradient(#f29f05, #fff);
        padding: 5px;
        border-radius: 0% !important;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important;
        border: none !important;
        // height: 250px !important;
    }

    .image {
        height: 168px !important;
        width: 100%;
        border-radius: 0px !important;
    }

    h4 {
        // width: 84%;
        text-align: center;
        color: #8c0d0d;
        font-weight: 800;
        font-size: 22px !important;
        line-height: 40px;
        text-transform: uppercase;
        margin: 0 !important;
    }

    .title,
    .subtitle {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        color: var(--primary);


        // text-align: center;
        // color: #8c0d0d;
        // font-weight: 600;
        // line-height: 40px;
        // text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
    }

    .subtitle {
        color: black;
    }

    .button_theme {
        text-align: center;
        width: 53%;
    }

    .buttonSection {
        text-align: center;
        // margin: 20px 0px;
    }

    text-align: -webkit-center;
}

@media screen and (max-width:767px) {
    .CardComponent {
        margin: 15px 0px;
    }
}