.servies_containerr_main {
    // width: 75%;
    // margin: 20px auto;

    @media screen and (max-width:767px) {
        .desktop {
            display: none !important;
        }
    }

    @media screen and (min-width:767px) {
        .mobile {
            display: none !important;
        }
    }
}