.ContactUs {
    font-family: sans-serif;

    .banner-inner {
        width: 100%;
    }

    .Contact-us_Main {
        .section {
            display: flex;
            justify-content: space-between !important;
            // width: 90%;
            margin-top: 100px;

            span {
                color: var(--primary);

            }

            h3 {
                color: var(--primary);
                font-weight: 700;
                margin-bottom: 30px;

            }

            h6 {
                font-weight: 700;
                // line-height: 30px;
                line-height: 28px;
                font-size: 17px;
                margin-bottom: 8px;
                margin-top: 8px;
            }

            .left {

                width: 45%;

                .custom_input,
                .ant-select-selector {
                    padding: 6px;
                    border-radius: 0px;
                    background-color: #EFEFEF;
                }

                .button_theme {
                    width: fit-content;
                    border-radius: 0 !important;
                    padding: 10px;
                    font-family: sans-serif !important;
                    text-transform: capitalize !important;

                }
            }

            .addres {
                display: flex;
                gap: 20px;
            }

            .right {
                width: 45%;

                .p,
                .addres {
                    width: 60%;
                    font-size: 14px;
                    line-height: 30px;

                }

            }
        }
    }

    .MapSection {
        iframe {

            width: 100%;
        }
    }

    @media screen and (max-width:767px) {

        .section {
            display: block !important;

            .left,
            .right {
                width: 100% !important;
            }
        }


    }
}