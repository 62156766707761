.BlogCardComponent {
  .card {
    border: none !important;
    background-color: transparent !important;
    // padding: 0px 20px;
    padding-right: 32px;
  }

  .description_text {
    height: 90px !important;
    overflow: hidden !important;
    font-family: "Open Sans", sans-serif !important;
  }

  .card-body {
    padding: 0 !important;
    // width: 80%;
  }

  h5 {
    // color: #8c0d0d;
    // font-weight: 700;
    // font-size: 20px;
    // text-transform: uppercase;
    // padding: 15px 0;
    // margin: 0;
    // font-family: 'Oswald', sans-serif !important;

    background-image: url("../../assets/line.jpg");
    background-repeat: no-repeat;
    background-position: top left;
    padding-top: 15px;
    margin-top: 30px;
    font-size: 17px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-family: "Oswald", sans-serif;
    height: 50%;
    min-height: 50px;
  }
  .Image {
    border: 4px solid #fff;
    border-radius: 4px;
    padding: 0px;
    // width: auto !important;
    // width: 80%;
    width: 100%;
    min-height: 230px !important;
  }
}
.read_more {
  font-size: 14px;
  text-transform: uppercase;
//   color: #8c0d0d;
  font-weight: 500;
  letter-spacing: 0.8px;
}
