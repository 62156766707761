.HeaderSection {
  font-family: "Oswald";

  .navbar-nav {
    align-items: center;
    @media (max-width: 767px) {
      align-items: left !important;
    }
  }

  .list-group-item:first-child {
    border-radius: 0 !important;
  }

  .navbar-collapse {
    justify-content: right !important;
  }

  .list-group-item {
    padding: 5px 10px;
  }

  .dropdown-menu {
    padding: 0 !important;
    width: max-content !important;
  }

  .itemsName {
    margin: 2px 0px;
  }

  .list-group-item {
    background-color: var(--primary);
    color: white !important;
    cursor: pointer;
  }

  .itemsName {
  }

  .active {
    background-color: var(--secondary) !important;
    border: none !important;
  }

  .TopStrip {
    background-color: var(--primary);
    font-family: "Open Sans", sans-serif;
    padding: 5px 0px;
    text-align: right !important;

    a {
      font-size: 18px;
      color: #fff;
      font-weight: 700 !important;
      text-transform: uppercase;
      // padding: 0px 8px;
      padding: 5px 15px;
      display: inline-block;
    }
  }

  // @media (min-width:767px) {
  //     .navbar-nav {
  //         align-items: center;
  //     }
  // }

  .nav-link {
    font-size: 14px !important;
    color: black;
    padding: 8px 15px !important;
    text-transform: uppercase;
    font-family: "Oswald" !important;
    text-shadow: 0.5px 0.5px 0px #000;
    font-weight: 300 !important;
  }

  .request_quote {
    background-color: #8c0d0d;
    text-shadow: 0.5px 0.5px 0px #8c0d0d;
    color: #fff !important;
    border-radius: 4px;
    font-weight: 300 !important;
    font-size: 14px !important;
    padding: 4px 12px !important;
  }

  .request_quote:hover {
    background-color: var(--secondary);
    text-shadow: 0.5px 0.5px 0px var(--secondary);
    color: #fff !important;
    border-radius: 4px;
    font-weight: 300 !important;
    font-size: 14px !important;
    padding: 4px 12px !important;
  }

  .customnav {
    padding: 0;
    cursor: pointer;
  }

  // @media (max-width:767px) {
  //     .Logo {
  //         width: 80%;
  //     }

  //     .navbar-nav {
  //         align-items: left !important;
  //     }

  //     .navbar-toggler {
  //         background: var(--primary);
  //         color: white !important;
  //     }

  //     .nav-link {
  //         font-weight: 300 !important;
  //     }
  // }

  /* Extra small devices (phones) */
  @media (max-width: 767px) {
    .navbar-nav {
      align-items: left !important;
    }

    .nav-link {
      padding: 0 !important;
    }
  }

  /* Small devices (tablets) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .nav-link {
      padding: 0 !important;
    }

    .navbar-nav {
      align-items: left !important;
    }
  }

  /* Medium devices (desktops) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .nav-link {
      padding: 0 !important;
    }

    .navbar-nav {
      align-items: left !important;
    }
  }

  /* Large devices (desktops) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .nav-link {
      padding: 0 !important;
    }

    .navbar-nav {
      align-items: left !important;
    }
  }

  /* Extra large devices (large desktops) */
  @media (min-width: 1200px) {
    /* Your CSS styles for extra large devices here */
    .navbar-nav {
      align-items: left !important;
    }
  }
}
.HeaderSection {
  .navbar-nav .nav-link {
    color: #000;

    &.active {
      color: var(--primary) !important;
      font-weight: bold;
      background: transparent !important;
    }

    &:hover {
      color: var(--primary);
    }
  }

  // Style for the dropdown title color
  .dropdown-toggle {
    color: #333; // Default color for the dropdown title
    font-weight: 600;

    &:hover {
      color: var(--primary); // Hover color for the dropdown title
    }
  }

  // Style for the dropdown item color
  .dropdown-menu .dropdown-item {
    color: #000;

    &.active,
    &:hover {
      color: var(
        --primary
      ) !important; // Color when dropdown item is active or hovered
      font-weight: bold;
    }
  }
}
