.ProductCard {
  .buttontheme {
    width: 100%;
  }
  margin: 50px 0px;
  .CardComponent {
    .custom_card {
      cursor: pointer;
      background-color: var(--secondary) !important;
      .rated {
        font-weight: 600;
      }
    }
  }
  @media (max-width: 767px) {
    margin: 0 !important;
  }
}
