.BookingHistory {
    .emptyCart{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p{  
        font-size: inherit;
        font-weight: 400;
        }
        img{
          width: 400px;
          height: 300px;
        }
        .shoppingBtn{
          background-color: #3D3C6E;
          color: white;
          padding: 10px 15px;
          border: none;
        //   margin: 20px 0;
        }
      }
    // background-color: #E58139;
    .ant-tabs-nav {
        // position: sticky !important;
        // top: 0;
        // z-index: 11111 !important;
        // background: white !important;
        // padding-bottom: 20px;

    }

    .ant-tabs-tab-btn {
        color: var(--primary) !important;

    }

    .ant-tabs-tab,
    .ant-tabs-tab-active {
        color: var(--secondary) !important;
    }

    .ant-tabs-content-holder {
        // margin: 40px 0px;
        // padding: 10px 0px;
    }

    .BookingHistory_card {
        border-radius: 16px !important;
        border-left: 3px solid #E58139 !important;
        background: #FBF7F5 !important;
        // margin: 30px !important;
        width: 40%;
        padding: 20px;
        margin: 20px;

        .top {
            display: flex;
            align-items: center !important;
            justify-content: space-between !important;

            .approval_Status {
                border-radius: 30px;
                background: #E58139;
                color: #FFF;
                padding: 5px;
                margin: 0px 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;

            }
        }

        h4 {
            color: #000;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            margin: 5px;
        }

        h5 {
            color: #000;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            /* 148.75% */
        }

        .date ,.City_one{
            display: flex;

            .icon {
                color: gray;
                font-size: 20px;
                margin-right: 10px;
            }
        }
    }

    @media (max-width:1000px) {
        .BookingHistory_card {
            width: 60% !important;
            margin: 10px 0px !important;
        }

    }

    @media (max-width:767px) {
        .BookingHistory_card {
            width: 100% !important;
            margin: 10px 0px !important;

        }

    }

}