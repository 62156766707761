.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1170px !important;
  padding: 0px 15px;
  /* width: 100%;
  margin: 0px 15px !important; */
}



.link {
  color: inherit;
  text-decoration: none;
}

.buttontheme {
  padding: 6px 30px;
  text-transform: uppercase;
  font-weight: 300;
  background-color: var(--primary);
  display: inline-block;
  font-family: "Oswald", sans-serif;
  color: #fff;
  border-radius: 4px;
  margin-top: 5px;
  border: none;
  font-size: 14px;
}

.buttontheme2 {
  padding: 6px 30px;
  text-transform: uppercase;
  font-weight: 300;
  background-color: var(--secondary);
  display: inline-block;
  font-family: "open", sans-serif;
  color: #fff;
  border-radius: 4px;
  border: none;
  font-size: 14px;
}
.buttontheme2:hover{
  background-color: var(--primary) !important;

}
.buttontheme:hover {
  padding: 6px 30px;
  text-transform: uppercase;
  font-weight: 300;
  background-color: var(--secondary) !important;
  display: inline-block;
  font-family: "Oswald", sans-serif;
  color: #fff !important;
  border-radius: 4px;
  border: 1px solid var(--secondary) !important;
  font-size: 14px;
}
.form_inner {
  text-align: left;
}
.bottom_text {
  text-align: center !important;
}
.book_ctm_btn {
  background-color: #8c0d0d;
  text-shadow: 0.5px 0.5px 0px #8c0d0d;
  color: #fff !important;
  border-radius: 4px;
  font-weight: 300 !important;
  font-size: 14px !important;
  padding: 4px 12px !important;
  border: none !important;
}
.book_ctm_btn:hover {
  background-color: #8c0d0d;
  text-shadow: 0.5px 0.5px 0px #8c0d0d;
  color: #8c0d0d !important;
  border-radius: 4px;
  font-weight: 300 !important;
  font-size: 14px !important;
  padding: 4px 12px !important;
  border: 1px solid #8c0d0d !important;
}
