.moving-inventory-form {
  // padding: 30px;
  padding: 10px 5px;
  @media (max-width: 767px) {
    padding: 30px 10px
  }
  margin: 30px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  .form_layout {
    height: 43dvh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

.form_layout::-webkit-scrollbar {
display: none;
}

  .Heading {
    color: #8c0d0d;
    font-weight: 700;
    margin: 20px 0px;
  }
  .Heading_small {
    color: #8c0d0d;

    font-size: medium;
    font-weight: 600;
    margin: 20px 0px;
  }
  .divider_Color {
    background: #8c0d0d;
  }
  .inventory-table {
    .inventory-header {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .inventory-row {
      display: flex;
      align-items: center;
      padding: 5px 0;

      .inventory-item {
        font-weight: bold;
        .dimensions {
          font-size: 0.9em;
          color: #888;
        }
      }

      .ant-input {
        width: 100%;
      }
    }
  }
  .special-notes {
    font-weight: 700;
    margin: 10px 0;
  }
  .custom-textarea {
    border: 3px solid #f29f05 !important; /* Custom border color */
    border-radius: 4px; /* Optional: adjust border radius */
  }

  .custom-textarea:hover,
  .custom-textarea:focus {
    border-color: #f29f05 !important;
    box-shadow: none;
  }
  .notes-container {
    background-color: #f4a261;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;

    h4 {
      display: block;
      font-weight: 600;
    }

    p {
      margin: 8px 0 0 0;
      line-height: 1.1;
      font-size: 18px;
    }
  }
}
