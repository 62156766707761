.BookingComponent {
    font-family: 'Open Sans', sans-serif;

    h5 {
        // font-size: 26px;
        font-weight: 700;
        color: #8c0d0d;
        // padding-bottom: 8px;
        text-transform: uppercase;
        text-align: center;
    }

    h6 {
        font-weight: 700;
        color: #8c0d0d;
        // padding-bottom: 8px;
        text-align: center;

    }
    .form_layout{
        margin: 30px 0px;
    }
}