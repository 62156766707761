.BlogComponent {
    font-family: 'Open Sans', sans-serif;
    // padding: 0px 10px;
    .preNextButton {
        .preMoreBtn,
        .preMoreBtn {
            margin: 4px;
            padding: 4px;
            color: #d4d4d4;
            font-size: 24px;
            border: 3px solid #d4d4d4;
            border-radius: 0;
        }
    }
    .blogs_for_home{
        margin: 30px 0px;
    }
    .buttontheme{
        background-color: transparent !important;
        color: var(--primary) !important;
        padding: 0 !important;
        margin: 0 !important;
        font-family: 'Open Sans', sans-serif !important;

    }
   
 
}