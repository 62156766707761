.HomeContact {
    font-family: 'Open Sans', sans-serif;
    padding: 0px 10px;

    h3 {
        font-family: 'Oswald', sans-serif !important;
    }

    :where(.css-dev-only-do-not-override-zl9ks2).ant-form-item {
        margin-bottom: 10px !important;
    }

    :where(.css-dev-only-do-not-override-zl9ks2).ant-select .ant-select-arrow {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        font-style: normal;
        line-height: 1;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 50%;
        inset-inline-start: auto;
        inset-inline-end: 11px;
        height: 12px;
        margin-top: -15px;
        font-size: 12px;
        pointer-events: none;
        transition: opacity 0.3s ease;
        background: #FFBB3E;
        padding: 15px;
    }

    label {
        color: #fff !important;
        text-transform: capitalize;
        font-size: 16px !important;
        font-family: 'Oswald', sans-serif;
        font-weight: 300 !important;
        // padding-top: 12px;
    }

    .left {
        // margin: 10px 20px;
    }

    svg,
    a {

        color: #8c0d0d;
        text-decoration: none;
        font-weight: 700 !important;

    }

    p {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: #000;
        font-size: 16px;
        padding-bottom: 8px;

        span {
            font-family: 'Open Sans', sans-serif !important;
            font-weight: 600;
            color: #000;
            font-size: 16px;
            padding-bottom: 8px;
            display: inline-block;
            width: 100%;
        }
    }

    :where(.css-dev-only-do-not-override-zl9ks2).ant-input-outlined {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0;
        color: #fff;
        border: none;
        box-shadow: none;
    }

    :where(.css-dev-only-do-not-override-zl9ks2).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0;
        color: #fff;
        border: none;
        box-shadow: none;
    }

    // background: #ffffff;
    .space {
        width: 100% !important;
    }

    .para {
        font-size: 17px;
        padding-bottom: 30px;
        line-height: 28px;
        margin: 0 !important;
    }

    .right {
        background-image: url("../../assets//contact-form-background.jpg");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: cover;
        display: inline-block;
        padding: 40px 15px;
        align-self: center;

        h5 {
            font-size: 20px;
            text-transform: uppercase;
            font-family: 'Oswald', sans-serif;
            color: #fff;
            text-align: center;
            font-weight: 600;
            padding-bottom: 20px;
        }

        .button_theme {
            width: 100%;
            background-color: #ffbb3e;
            border-radius: 0;
            color: #1c1c1c;
            border: none;
            box-shadow: none;
            margin: 25px 0 0;
            font-family: 'Oswald', sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    .detail {
        display: flex;
        gap: 20px;

        strong {
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            color: #000;
            font-size: 18px;
            text-transform: uppercase;
            padding-bottom: 10px;
            display: inline-block;
        }
    }
}

@media (max-width:767px) {
    .HomeContact {
        display: block;

        .left,
        .right {
            width: 100% !important;
        }
    }

}