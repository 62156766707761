.Aboutus {
    .AboutUsSectionInner {
        width: 100%;
    }

    h1 {
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
    }

    p {
        line-height: 30px;
        font-family: 'Open Sans', sans-serif;
    }

    .left {
        width: 41.6% !important;
        padding: 0px 15px !important;
    }

    .right {
        width: 58.3%;

        img {
            padding-top: 30px;
            // width: 100% !important;
        }
    }
    @media only screen and (max-width: 767px) {
       .right{
        display: none;
       }
       .left{
        width: 100% !important;
       }
    }

    @media only screen and (max-width: 600px) {
        img {
            width: 100%;
        }
    }

    @media only screen and (min-width: 600px) {
      
    }

    @media only screen and (min-width: 768px) {
        // img {
        //     width: 100%;
        // }
    }

    @media only screen and (max-width: 1200px) {
        img {
            width: 100% !important;
        }
    }

    @media only screen and (min-width: 1200px) {}



}