.ServiceDetails {
  padding: 50px 0;
  h5 {
    font-size: 26px;
    font-weight: 700;
    color: #8c0d0d;
    padding-bottom: 8px;
    text-transform: capitalize;
  }
  p {
    line-height: 28px;
  }
  .Section {
    // align-items: center;
    align-content: center;
    .left {
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
  .content {
    // margin-top: 20px;
    padding-top: 8px;
  }
  @media (max-width: 767px) {
    .Section {
      .left {
        margin: 20px 0px;
      }
      img {
        width: 100% !important;
      }
      .content {
        // margin-top: 20px;
        padding-top: 20px;
      }
    }
  }
}
