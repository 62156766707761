.order {
    h2 {
        color: var(--primary);
        font-weight: 800;
    }
    .emptyCart{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p{  
        font-size: inherit;
        font-weight: 400;
        }
        img{
          width: 400px;
          height: 300px;
        }
        .shoppingBtn{
          background-color: var(--primary);
          color: white;
          padding: 10px 15px;
          border: none;
        //   margin: 20px 0;
        }
      }

    // body {
    //     background-color: mintcream;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column;
    //   }
    .card {
        // max-width: 30em;
        flex-direction: row;
        // background-color: #696969;
        color: black;
        border: 0;
        box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
        margin: 3em auto;
    }

    .card.dark {
        // color: #fff;
    }

    .order_id {
        // font-weight:700;
        color: gray;
    }

    .card.card.bg-light-subtle .card-title {
        // color: dimgrey;
    }

    .card img {
        // max-width: 25%;
        width: 100px;
        margin: auto;
        padding: 0.5em;
        border-radius: 0.7em;
    }

    .card-body {
        display: flex;
        justify-content: space-between;
    }

    .text-section {
        max-width: 60%;
    }

    .cta-section {
        max-width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    .cta-section .btn {
        padding: 0.3em 0.5em;
        /* color: #696969; */
    }

    .card.bg-light-subtle .cta-section .btn {
        // background-color: #898989;
        // border-color: #898989;
    }

    @media screen and (max-width: 475px) {
        .card {
            font-size: 0.9em;
        }
    }
}