body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Oswald', sans-serif;
  font-family: 'Open Sans', sans-serif;
  /* color: black; */
  font-size: 14px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary: #8C0D0D;
  --secondary: #f29f05;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
  /* monospace; */
}
.link{
  color: inherit;
  text-decoration: none;
}
:where(.css-dev-only-do-not-override-1qfezbu).ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
  text-align: left !important;
}