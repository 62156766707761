.DeleteAccount {
    margin: 20px 0px;
    font-size: 16px;
  li {
    margin-left: 50px;
    list-style-type: circle;
  }
  h6{
    font-weight: 700;
  }
}
