.forgot_form {
  padding: 20px;
  width: 40%;
  margin: 10px auto !important;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  .forgot_form_button {
    border: none !important;
    width: 100%;
    padding: 10px;
    color: white !important;
    background-color: var(--primary);

    h6 {
      margin: 0;
    }
  }
  .forgot_form_button:hover {
    background-color: var(--secondary) !important;

  }
  @media (max-width:767px) {
    width: 100%;
    
    
  }
}
