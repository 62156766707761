.CheckoutPage {
    .emptyCart{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
        p{  
        font-size: inherit;
        font-weight: 400;
        }
        img{
          width: 400px;
          height: 300px;
        }
        .shoppingBtn{
            background-color: var(--primary);

          color: white;
          padding: 10px 15px;
          border: none;
        //   margin: 20px 0;
        }
      }
    .priceitem {
        display: flex;
        justify-content: space-between;
    }
    .total_amount{
        font-weight: 600 !important;
    }
    .remove_div{
        text-align-last: center;
    }
    .remove_button{
        margin: 10px;
        cursor: pointer;
        font-weight: 800;
    }
    .remove_button:hover{
        color:var(--primary);
    }
    h2 {
        color: var(--primary);
        font-weight: 700;
    }
    .pointer{
        margin: 0px 5px;
        cursor: pointer;
    }
    .top {
        display: flex !important;
        justify-content: space-between !important;
        h5 {
            font-weight: 700;
        }
        .CHANGE {
            color: var(--secondary);
            font-size: 16px;
            cursor: pointer;
        }
    }
    .section {
        margin: 20px 0px;
        .Deliver_Card {
            width: 70%;
            margin: 20px 0px;
            p {
                font-weight: 500;
                font-size: 18px;
            }
        }
        .ShoppingCard {
            // width: 70%;
            margin: 20px 0px;
            .Shoppingproduct {
                .product {
                    margin: 40px 0px;
                    // width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .product_image {
                        width: 20%;
                        img {
                            width: 100%;
                            border-radius: 10px;
                            // margin: 10px;
                        }
                    }
                    .product_details {
                        width: 65%;
                        margin: 10px;
                    }
                    .amount {
                        font-weight: 800;
                    }
                    .amount_total_item{
                        border: 1px solid;
                        padding: 2px 24px;
                    }
                    .qut {
                        display: flex;
                        justify-content: space-between;
                        // width: 15%;
                    }
                    .icon {}
                }
                .qut {}
            }
        }
    }
    .place_order_section {
        h5 {
            font-weight: 800;
        }
    }
}