.AboutUSComponent{
    padding: 50px 0;
    h5{
        font-size: 26px;
        font-weight: 700;
        color: #8c0d0d;
        padding-bottom: 8px;
        text-transform: capitalize;
    }
    p{
        line-height: 28px ;
        font-size: 14px ;
    }
}