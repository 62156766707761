.profile {
    padding: 50px 0px;

    .profiletop {
        display: flex;
        place-items: center;
        margin: 10px 0px;

        .Profile_top_images {
            height: 50px;
            width: 50px;

        }

        h2 {
            margin: 10px;
        }
    }

    .custom_file_upload input[type="file"] {
        display: none !important;
    }

    h6 {
        color: rgba(51, 51, 51, 0.60);
        font-family: SF Pro Display;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .profile_inner {
        border-radius: 8px;
        // background: rgba(238, 35, 37, 0.10);
        background-color: #efeeee;
        // box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
        // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
        padding: 20px;
    }

    .Picture {
        width: 100px !important;
        height: 100px !important;
        padding: 20px;
        border-radius: 50px;
    }

    .section1 {
        display: flex;
        // justify-content: space-between;
        align-items: center;
    }

    .section2 {
        display: flex;
        justify-content: space-between;

        .btn_tab {
            width: 20%;
        }

        .Form_ctm {
            margin: 30px auto !important;
            width: 50% ;
        }

        .react-tel-input .form-control {
            width: 100%;
        }

        .line {
            width: 1px;
            background-color: #696969;
            border: 2px solid #696969;
            margin: 0px 10px;
        }

        .btn_theme_div {
            border-radius: 8px;
            // background: #174F78;
            // background: transparent !important;
            box-shadow: none !important;
            color: black;
            display: block;
            width: 100%;
            margin: 20px 0px;
            border: none !important;
            // font-weight: bolder;
        }

        .buttons {
            display: flex;
            // margin: 100px 0px;
        }
    }

    .input_container {
        position: relative;

        span {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .error_message {
        padding-top: 5px;
        color: red !important;

    }
}

@media (max-width:767px) {
    .section2 {
        display: block !important;

        .btn_tab {
            width: 100% !important;
        }

        .line {
            display: none !important;
        }

        
        .buttons {
            margin: 20px 0px !important;
        }
        .Form_ctm {
            width: 100% !important;
        }
    }
}