.chatButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    border-radius: 100%!important;
    border: none !important;
    background-color: var(--primary);
    color: white;


    .icon {
        border-radius: 100% !important;
    }
    
}