.ProductDetails {
    img {
        width: 100%;
        height: 200px;
    }

    .otherdetail {
        display: flex;
        justify-content: space-between;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        width: 100%;

        .btn1 {
            // width: 5%;
            cursor: pointer;
        }

        .btn2 {
            // width: 5%;
            text-align: center;
            font-weight: 900;
            // cursor: pointer;

        }

        .btn3 {
            // width: 5%;
            cursor: pointer;

        }

    }

    .buttontheme {
        width: 100%;
        // margin: auto;
        border-radius: 5px;
        padding: 7px;
        color: white;
        font-weight: 400;
        // width: 75%;

    }

    .icon,
    .amount {
        border-radius: 5px;

        padding: 10px;
        color: white;

        background: var(--primary);
        text-align: center;
        // /* border-radius: 44%; */

    }

    .amount {
        padding: 6px 15px !important;
    }
}