.Blogs{
    padding: 50px 0;
    .BlogsSection{
        
    }
    .buttontheme {
        font-family: 'Open Sans', sans-serif !important;
        text-transform: capitalize;
    }
    @media (max-width:767px) 
    {
        .card{
            margin: 15px 0px !important; 
        }
        
    }
}