.FooterSection {
    .footerArea {
        background-color: #1c1c1c;
        padding: 50px 0;
        color: #aaa;

        .section1_col {
            padding: 16px 19px;
        }

        h4 {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            color: #fff;
            position: relative;
            border-bottom: 1px solid rgba(255, 255, 255, .1);
            padding-bottom: 15px;
            margin-bottom: 15px;
            font-family: 'Oswald', sans-serif !important;
        }

        li {
            border-bottom: 1px solid rgba(255, 255, 255, .1);
            position: relative;
            display: block;
            padding: 3px 0;
            color: #aaa;
            // padding-left: 18px;
            line-height: 32px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-transform: uppercase;
        }

        .li_desc {
            display: flex;
            gap: 10px;
            font-size: 14px;
            line-height: 28px;
        }

    }

    .sec1 {
        // bottom: 0;
        // padding-top: 10px;
        padding: 10px;
        color: white;
        background-color: black !important;
        font-family: 'Open Sans', sans-serif;

        p {
            font-size: 14px;
            margin: 0;
            text-align: center;
            line-height: 28px;
        }
    }

    @media (max-width:767px) {
        .footerArea h4{
            font-size: 24px !important;
            margin-top: 50px !important;

        }

    }
}