.landingPage {
    body{
        line-height: 0px !important;
    }
    .h3heading {
        font-size: 30px;
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
        border-bottom: 5px solid #8c0d0d;
        display: inline-block;
        padding-bottom: 12px !important;
        margin-bottom: 30px !important;
        font-family: 'Oswald', sans-serif !important;
    }

    .section1 {}

    .section2 {
        // position: absolute;
        // width: 100%;
        // top: 98%;
        margin-top: -150px;
        // margin-top: -90px;
        padding: 0 0 50px;
    }

    .section3 {
        // padding: 50px 0 0;
        background: #efefef;
        padding: 50px 0 0;
        height: 420px;
    }

    .section4 {
        background-image: url("../../assets/bannerbackground.jpg");
        color: white;
        padding: 40px;
        text-align: center;
        // margin: 20px 0px;
        margin-top: 140px;
        padding: 35px 0 30px;

        .section4_heading {
            text-align: center;
            color: #fff;
            text-align-last: center !important;
            margin: 0 !important;
        }

        h5 {
            color: var(--secondary);
        }

        .color_white {
            color: white;
        }
    }

    .section5 {
        display: flex;
        // justify-content: space-between;
        width: 100%;
        margin: 5px 0px;
        gap: 5px;

        .imgsection {
            // margin: 4px;
            width: 25%;

            img {
                width: 100%;
            }
        }
    }

    .section6 {
        padding: 50px 0;
    }

    .section7 {
        padding: 50px 0 50px;
        background-color: #EFEFEF;
    }

    .section9 {
        .MapSection {
            iframe {
                width: 100%;
                height:305px;
            }
        }
    }

    @media (max-width: 767px) {
        .section5 {
            display: flex;
            flex-wrap: wrap;
            gap: 2px !important;
            justify-content: center;
            /* Distribute items evenly */
        }
        .imgsection {
            flex-basis: 48%;
            /* Each image takes almost half of the container's width */
            // margin-bottom: 20px; /* Margin between images */
        }
        .col{
            width: 100%;
        }

    }

    /* Extra small devices (phones) */
    @media (max-width: 575.98px) {
        .section2 {
            margin-top: 0px;

        }

        .section3 {
            height: auto;
            padding: 50px 0px;

            img {
                width: 100%;
            }
        }

        .section4 {
            margin: 0px;
        }

    }

    /* Small devices (tablets) */
    @media (min-width: 576px) and (max-width: 767.98px) {
        .section2 {
            margin-top: 0px;

        }

        .section3 {
            height: auto;
            padding: 50px 0px;

            img {
                width: 100%;
            }
        }

        .section4 {
            margin: 0px;
        }
    }

    /* Medium devices (desktops) */
    @media (min-width: 768px) and (max-width: 991.98px) {
        .section2 {
            margin-top: -70px;

        }

        .section3 {
            height: auto;
            padding: 50px 0px;

            img {
                width: 100%;
            }
        }

        .section4 {
            margin: 0px;
        }
    }

    /* Large devices (desktops) */
    @media (min-width: 992px) and (max-width: 1199.98px) {}

    /* Extra large devices (large desktops) */
    @media (min-width: 1200px) {
        /* Your CSS styles for extra large devices here */
    }

}